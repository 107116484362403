<template>
    <div>
        <vx-card v-if="!oneByOne && !massive">
            <div class="vx-row">
                <div class="vx-col lg:w-1/2 xs:w-full">
                    <h2 class="text-info">{{ this.$t('Nueva Tienda') }}</h2>
                    <p class="text-info">{{ this.$t('Selecciona un método para agregar tus tiendas') }}</p>
                </div>

                <div class="vx-col lg:w-1/2 xs:w-full sm:mt-5">
                    <button @click="downloadFileFormat()" class="vs-component vs-button rounded-full vs-button-info vs-button-filled small lg:float-right">
                        <img :src="require(`@/assets/icons/DownloadFileIcon.svg`)" width="20px" class="inline-block align-middle mr-2"/>
                        {{ this.$t('Descargar formato de Carga Masiva') }}
                    </button>
                </div>

                <!-- caja uno a uno -->
                <div class="vx-col lg:w-1/2 xs:w-full mt-10">
                    <h3 class="text-gray">{{ this.$t('Crear una Tienda') }}</h3>
                    <div class="w-full border-2 border-dashed mt-5 rounded-lg text-center pt-16 pb-10 pl-32 pr-32 cursor-pointer height" @click="$router.push( { path : '/app/stores/new/form'} )">
                        <img :src="require(`@/assets/icons/PlusCircleGrayIcon.svg`)" width="50px" class="inline-block align-middle"/><br/><br/>
                        <h4>{{ this.$t('Crear tienda 1 a 1') }}</h4><br/>
                        <span>{{ this.$t('Agrega todas tus tiendas una por una con nuetro formulario de cargue de tiendas') }}</span><br/><br/>
                        <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full">{{ this.$t('Empezar') }}</vs-button>
                    </div>
                </div>

                <!-- caja masiva -->
                <div class="vx-col lg:w-1/2 xs:w-full mt-10">
                    <h3 class="text-gray">{{ this.$t('Carga Masiva de Tiendas') }}</h3>

                    <div class="w-full border-2 border-dashed mt-5 rounded-lg text-center pt-16 pb-10 pl-32 pr-32 cursor-pointer height" @click="$router.push( { path : '/app/stores/new/massive'} )">
                        <img :src="require(`@/assets/icons/CloudUploadIcon.svg`)" width="50px" class="inline-block align-middle"/><br/><br/>
                        <h4>{{ this.$t('Cargar todas tus tiendas') }}</h4><br/>
                        <span>{{ this.$t('Agrega todas tus tiendas con nuestro formato de carga masiva') }}</span><br/><br/>
                        <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full">{{ this.$t('Empezar') }}</vs-button>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            oneByOne: false,
            massive: false,
        }
    },

    methods: {
        downloadFileFormat() {
            this.$vs.loading();
            this.$store.dispatch( 'getFileUrl', '/documents/example-formats/tiendas.xlsx' )
            .then( url => {
                this.$vs.loading.close();
                if( url ) {
                    const link = document.createElement('a');
                    
                    link.setAttribute( 'download', 'true' );
                    link.href = url;
                    link.click();
                    link.remove();
                }
            });
        },

    },
})
</script>

<style lang="scss">
.height {
    height: 90%;
}
</style>
